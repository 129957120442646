var menuOpen = false;
var burgerBtn = document.querySelector(".burger-menu");
var menuBurgerBtn = document.querySelector(".js-close-menu-btn");
let navElement = document.querySelector('#navigation');
navElement.inert = true;
// console.log(navElement);
burgerBtn.addEventListener('click', function(){
  burgerBtn.classList.toggle('change');
  document.querySelector("body").classList.toggle('menu-open');
  navElement.inert = !(navElement.inert);
});
menuBurgerBtn.addEventListener('click', function(){
  burgerBtn.classList.toggle('change');
  document.querySelector("body").classList.toggle('menu-open');
  navElement.inert = !(navElement.inert);
});
