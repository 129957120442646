var textToAnimate = document.querySelectorAll('.js-animate-text');
var imagesToAnimate = document.querySelectorAll('.js-animate-fade');
var sliderToAnimate = document.querySelectorAll('.js-animate-clip');
var homeSliderToAnimate = document.querySelectorAll('.js-animate-homeslider');
var heightToAnimate = document.querySelectorAll('.js-animate-height');
var isInViewport = function (elem) {
    var bounding = elem.getBoundingClientRect();
    return (
      // bounding.top >= 0 && (bounding.bottom <= window.innerHeight+(bounding.bottom-bounding.top)/2)
      (bounding.top >= 0) && (bounding.bottom <= window.innerHeight+(bounding.bottom-bounding.top)/2)
      // (bounding.top >= 0) && (bounding.bottom <= window.innerHeight+(bounding.bottom-bounding.top)/10)
    );
};
var doAnimation = function (elementList) {
  for(let j= 0;j<elementList.length;j++){
    if (isInViewport(elementList[j]) && !(elementList[j].classList.contains('js-do-animation'))) {
      elementList[j].classList.add('js-do-animation');
    }
  }
};


var projectElemList = document.querySelectorAll('.page-template-tmpl_work_archive .works-grid .grid-element');
console.log(projectElemList);
var blurWhenAppear = function(){
  projectElemList = document.querySelectorAll('.page-template-tmpl_work_archive .works-grid .grid-element');
  for(let j= 0;j<projectElemList.length;j++){
    // if (!isInViewport(projectElemList[j]) && (projectElemList[j].classList.contains('js-do-animation'))) {
    if ( !isInViewport(projectElemList[j]) && (projectElemList[j].classList.contains('js-anim-after')) ) {
      console.log('element out of viewport');
      projectElemList[j].classList.remove('js-anim-after');
    }
    // if ( isInViewport(projectElemList[j]) && !(projectElemList[j].classList.contains('js-do-animation')) ) {
    if ( isInViewport(projectElemList[j]) ) {
      projectElemList[j].classList.add('js-anim-after');
    }
  }
};



doAnimation(textToAnimate);
doAnimation(imagesToAnimate);
doAnimation(sliderToAnimate);
doAnimation(homeSliderToAnimate);
doAnimation(heightToAnimate);

window.addEventListener('scroll', function(event) {
  doAnimation(textToAnimate);
  doAnimation(imagesToAnimate);
  doAnimation(sliderToAnimate);
  doAnimation(homeSliderToAnimate);
  doAnimation(heightToAnimate);
  if (projectElemList) {
    blurWhenAppear();
  }
}, false);
