const langSwitcher = document.querySelectorAll('.js-language-switcher-toggle');

if (langSwitcher) {
    const toggleLang = () => {
        document.body.classList.toggle('lang-switcher-open');
    };
    for (let i = 0; i < langSwitcher.length; i++) {
        langSwitcher[i].addEventListener('click', toggleLang);
    }
}
